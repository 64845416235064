var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Öneriler","icon":"mdi-head-lightbulb-outline","hide-edit":!_vm.can('edit-suggestion'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"add-route":_vm.can('edit-suggestion')
              ? { name: 'communication.suggestions.create' }
              : null,"add-enabled":_vm.clusterId},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'communication.suggestions.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.provider",fn:function({ item }){return [(item.provider_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.cluster-providers.show',
            params: { id: item.provider_id },
          }}},[_c('rs-table-cell-string',{attrs:{"value":item.provider,"limit":20}})],1):_vm._e()]}},{key:"item.resident",fn:function({ item }){return [(item.resident_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.residents.show',
            params: { id: item.resident_id },
          }}},[_c('rs-table-cell-string',{attrs:{"value":item.resident,"limit":14}})],1):_vm._e()]}},{key:"item.subject",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"value":value,"limit":26}})]}},{key:"item.date",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.description",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"limit":50,"value":value}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }